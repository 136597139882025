import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Homepage from '../HomePage'
import AboutPage from '../AboutPage'
import TeamSinglePage from '../TeamSinglePage'
import CausePage from '../CausePage'
import TestimonialPage from '../TestimonialPage'
import ContactPage from '../ContactPage'
import MissionVissionPage from '../MissionVissionPage'
import ErrorPage from '../ErrorPage'

const AllRoute = () => {

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route exact path='/' element={<Homepage />} />
          <Route path='home' element={<Homepage />} />
          <Route path='about' element={<AboutPage />} />
          <Route path='cause' element={<CausePage />} />
          <Route path='team-single/:id' element={<TeamSinglePage />} />
          <Route path='mission-vission/:id' element={<MissionVissionPage />} />
          <Route path='testimonial' element={<TestimonialPage />} />
          <Route path='contact' element={<ContactPage />} />
          {/* Catch-all route for 404 */}
          <Route path='*' element={<ErrorPage />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default AllRoute;