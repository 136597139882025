import React from 'react'
import { Link } from 'react-router-dom'
import shape from '../../images/cta-shape.png'



const CtaSection = (props) => {

    return (
      <div className={`wpo-cta-area ${props.ctClass}`}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="wpo-cta-section">
                <div className="wpo-cta-content">
                  <h2>{props.cTitle}</h2>
                  <a
                    href="https://divinetraining.co.za/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Enroll Now{" "}
                    <i
                      style={{ marginTop: "8px" }}
                      className="fi flaticon-next"
                    ></i>
                  </a>
                </div>
                <div className="volunteer-img">
                  <img
                    src={props.vImg}
                    alt=""
                    style={{ width: "365px", height: "512px" }}
                  />
                </div>
                <div className="shape">
                  <img src={shape} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
}

export default CtaSection;