import React, {Fragment} from 'react';
import Navbar from '../../components/Navbar';
import PageTitle from '../../components/pagetitle'
import Footer from '../../components/footer'
import Scrollbar from '../../components/scrollbar'
import Logo from '../../images/logo.png'
import Testimonial from '../../components/Testimonial';

const TestimonialPage =() => {
    return(
        <Fragment>
            <Navbar Logo={Logo}/>
            <PageTitle pageTitle={'Testimonial'} pagesub={'Testimonial'}/> 
            <Testimonial tClass={'wpo-testimonial-area-s3'}/>
            <Footer/>
            <Scrollbar/>
        </Fragment>
    )
};
export default TestimonialPage;

