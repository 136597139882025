import React from 'react';
import Services from '../../api/service';
import './Service5.css'; // Import the CSS file

const Service5 = (props) => {
    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (
        <section className="wpo-features-section-s6 section-padding">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-6">
                        <div className="wpo-section-title">
                            <span>DC Safe Space</span>
                            <h2>Our Featured Services</h2>
                        </div>
                    </div>
                </div>
                <div className="row service-list">
                    {Services.slice(12, 16).map((service, sitem) => (
                        <div className="col service-item" key={sitem}>
                            <div className="wpo-features-item">
                                <div className="wpo-features-icon">
                                    <div className="icon">
                                        <i className={`fi ${service.fIcon1}`}></i>
                                    </div>
                                </div>
                                <div className="wpo-features-text">
                                    <h2>{service.title}</h2>
                                    <p>{service.description}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    )
}

export default Service5;
